.App {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #1b1a2f; */
  background-color: #000000;
  z-index: -1;
}

.Main {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 52vw;
  overflow-y: auto;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #000000; */
}

.Stencil {
  position: absolute;
  opacity: 50%;
  top: 50%;
  width: 100%;
  height: 60vh;
  overflow: overlay;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #000000; */
}

img {
  transition: transform .1s ease-in-out;
}

.telegram {
  position: absolute;
  top: 3.3%;
  left: 14.1%;
  width: 12.2%;
}

.twitter {
  position: absolute;
  top: 10%;
  left: 77.5%;
  width: 12.3%;
}

.dextools {
  position: absolute;
  top: 68%;
  left: 5%;
  width: 19%;
}

.logo {
  position: absolute;
  pointer-events: none;
  top: 27%;
  left: 35%;
  width: 32%;
  z-index: 1;
  animation: scaleAnimation 1s infinite alternate;
}

@keyframes scaleAnimation {
  from {
    transform: scale(1); /* Начальный размер */
  }
  to {
    transform: scale(1.05); /* Конечный размер */
  }
}

.logo-layout {
  position: absolute;
  pointer-events: none;
  opacity: 90%;
  top: 24%;
  left: 35%;
  width: 35%;
}

.link {
  cursor: pointer;
  filter: drop-shadow(0 0 2vw rgb(6, 153, 238));
  animation: scaleAnimation 0.5s infinite alternate;
}

.hoverable:hover {
  transform: scale(1.05);
}

.main-2 {
  position: absolute;
  top: 6%;
  left: 28.3%;
  width: 15%;
}

.main-3 {
  position: absolute;
  top: 5%;
  left: 44.5%;
  width: 31%;
}

.main-5 {
  position: absolute;
  top: 28%;
  left: 61%;
  width: 14.5%;
}

.main-6 {
  position: absolute;
  top: 51%;
  left: 60%;
  width: 21%;
}

.main-7 {
  position: absolute;
  top: 50.5%;
  left: 82%;
  width: 12%;
}

.main-8 {
  position: absolute;
  top: 77%;
  left: 64.7%;
  width: 17%;
}

.main-9 {
  position: absolute;
  top: 77%;
  left: 45%;
  width: 19%;
}

.main-10 {
  position: absolute;
  top: 77%;
  left: 25%;
  width: 19%;
}

.main-12 {
  position: absolute;
  top: 34.5%;
  left: 25.5%;
  width: 17%;
}

.main-13 {
  position: absolute;
  top: 34%;
  left: 10%;
  width: 13.5%;
}